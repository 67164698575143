.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

.react-datepicker-popper {
   z-index: 11 !important;
  }

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}



.Toastify{
  position: absolute;
    z-index: 1000;
    /*  width: 365px; */
    top: 71px;
    right:0;
  }
  .Toastify__toast-icon{
    display: none !important;
  }
.Toastify__toast--default{
border: 1px solid #99D9B0 !important;
background: #F0FDF4 !important;
border-radius:6px !important;
top:10px !important;
padding:10px!important;
  }


  .Toastify__toast--error {
    border: 1px solid #f8acac !important;
    background: #fef2f2 !important;
    border-radius: 6px !important;
    padding: 10px !important;
  }
  .Toastify__toast-container--bottom-right{
    bottom: 71px !important;
  } 
  
  .Toastify__toast--error::after {
    position: absolute;
    color: #333333;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
    padding-top: 14px !important;
  }
  .Toastify__toast--warning {
    border: 1px solid #f8acac !important;
    background: #ffedd5 !important;
    border-radius: 6px !important;
    padding: 10px !important;
  } 
  .Toastify__toast--error::before {
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
  }
  .Toastify__toast--success {
    border: 1px solid #99d9b0 !important;
    background: #f0fdf4 !important;
    border-radius: 6px !important;
    padding: 10px !important;
  }
  .Toastify__toast--success::before {
    content: "";
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
  }
  .Toastify__toast-icon {
    display: none;
  }
  .Toastify__toast--success::after {
  position: absolute;
  color: #333333;
  font-size: 15px;
    font-weight: 700;
    left: 265px;
    padding-top: 14px !important;
  }

.feedback-checkbox label {
  display: none;
}

.tap-highlighter-none {
  -webkit-tap-highlight-color: transparent;
}

* {
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}


::-webkit-scrollbar{
  width:8px;
  height: 8px !important;
}
.modal-content{
  position: relative;
  margin-top: 16px;
}
.modal-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  /* width: 96.8%; */
  background: white;
  /* height: 24px; */
  position: fixed;
  top: 5px;
  left: 12px;
  right: 12px;
}

@media (max-width: 650px) {
  ::-webkit-scrollbar {
    display: none;
  }
  .modal-header {
    position: static !important;
    display:block !important;
    justify-content: flex-start ;
    left: -0px !important;
    }
    .modal-header button{
    display: none;
    }
}

@media (min-width: 650px) {
  .nps-scrollbar::-webkit-scrollbar {
    display: block !important;
  }
  .nps-scrollbar::-webkit-scrollbar:horizontal {
    display: none !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #C1C1C1 !important;
  }
  ::-webkit-scrollbar-track {
    background-color: #F1F1F1 !important;
  }

}

@media (max-width: 650px) {
  .nps-height-mob {
    max-height: calc(100vh - 74px) !important;
    top: 74px !important;
  }
}

@media (max-width: 767px) {
  .nps-desktop-modal .nps-desktop-modal-content {
    max-height: 100vh;
    overflow-y: auto;
    padding-bottom: 7rem!important;
  }
}
@media (min-width: 768px) {
  .nps-desktop-modal {
    max-height: fit-content;
    max-width: 672px;
    margin: auto;
    border-radius: 8px;
  }
  .nps-desktop-modal .nps-desktop-modal-wrap {
    max-height: calc(100vh - 100px);
    height: auto !important;
  }
  .nps-desktop-modal .nps-desktop-modal-content {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    padding-bottom: 7rem!important;
  }
}
.grecaptcha-badge {
  position: absolute !important;
  left: -9999px !important;
}